import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { operation_click } from '../../../../services/Rapports/RapportsApi';
import SqaureLoader from '../../../../components/Loader/SqaureLoader';
export default function Operation({ t, user_code, date1, date2, onClose }) {
        const [listePdvUser, setListePdvUser] = useState([]);
        const [loading, setLoading] = useState(true);

        const [zoomedImage, setZoomedImage] = useState(null); // État pour gérer l'image zoomée

        const handleImageClick = (image) => {
            if (zoomedImage === image) {
                setZoomedImage(null); // Si l'image est déjà zoomée, désactiver le zoom
            } else {
                setZoomedImage(image); // Sinon, zoomer l'image
            }
        };
    
        useEffect(() => {
            setLoading(true);
            if(user_code){
                operation_click(date1, date2, user_code)
                .then((result) => {
                    if (result?.status === 200) {
                        setListePdvUser(result?.data?.data);
                    }
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });
            }
           
        }, [user_code, date1, date2]);
    
        return (
            <div className="modal fade show" id="pdvVisité1" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="pdvVisité1" aria-hidden="true" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{ width: "max-content" }}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="pdvVisité"> <i className='fe fe-tag text-azure mr-1' /> {t('Operation')}   </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => onClose()}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {loading ? <SqaureLoader /> : (
                                <div className="table-responsive product-datatable" style={{ overflow: 'auto', height: '500px' }}>
                                    <table className="table table-bordered">
                                        <thead className="text-center bg-bleu fs-10" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "red !important" }}>
                                            <tr>
                                                <td className='fs-10'>#</td>
                                                <td className="wd-15p fs-10">{t('nompdv')}</td>
                                                <td className="wd-15p fs-10">{t('libelle')}</td>
                                                <td className="wd-15p fs-10">{t('emplacement')}</td>
                                                <td className="wd-15p fs-10">{t('date')}</td>
                                                <td className="wd-15p fs-10">{t('image')}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listePdvUser?.map((items, keys) => (
                                                <tr key={keys} className="text-center fs-10">
                                                    <td className='fs-10'>{keys + 1}</td>
                                                    <td>{items.nompdv}</td>
                                                    <td>{items.libelle}</td>
                                                    <td>{items.emplacement}</td>
                                                    <td>{moment(items.date).format('DD/MM/YYYY')}</td>
                                                    <td>
                                                        <img 
                                                        src={items?.image !== null ? items?.image : "../assets/images/users/23.png"} 
                                                        alt="notes" 
                                                        style={{
                                                            height: "50px",
                                                            cursor: "pointer",
                                                            transform: zoomedImage === (items?.image !== null ? items?.image : "../assets/images/users/23.png") ? 'scale(3)' : 'scale(1)', // Augmente à 3 pour un plus grand zoom
                                                            transition: 'transform 0.3s',
                                                            zIndex: zoomedImage ? 1 : 0,
                                                            position: zoomedImage ? 'relative' : 'static'
                                                        }} 
                                                        onClick={() => handleImageClick(items?.image !== null ? items?.image : "../assets/images/users/23.png")}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    {zoomedImage && (
                                            <div style={{ 
                                                position: 'fixed', 
                                                top: 0, 
                                                left: 0, 
                                                right: 0, 
                                                bottom: 0, 
                                                backgroundColor: 'rgba(0, 0, 0, 0.8)', 
                                                display: 'flex', 
                                                justifyContent: 'center', 
                                                alignItems: 'center', 
                                                zIndex: 1000 
                                            }} onClick={() => setZoomedImage(null)}>
                                                <img 
                                                    src={zoomedImage} 
                                                    alt="Zoomed" 
                                                    style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }} // Dimensions de l'image zoomée
                                                />
                                            </div>
                                        )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    

