/* eslint-disable array-callback-return */
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { toast, ToastContainer } from 'react-toastify'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getRapportGestionDashboard } from '../../../services/Rapports/RapportsApi'
import TableauAnalyse from './TableauAnalyse'
import TableauGG from './TableauGG'

export default function RapportAnalyse() {
    const { datedebut, datefin } = useParams('')
    //------ History -----------------
    const history = useHistory()
    // Formulaire
    const [today, settoday] = useState(moment(datedebut).format('YYYY-MM-DD'))
    const [fin, setfin] = useState(moment(datefin).format('YYYY-MM-DD'))
    const [listeDash, setlisteDash] = useState([])
    // PDV PLANIFIEE
    const [pdv_planifiee, setpdv_planifiee] = useState([])
    const [countPDV_planifieee, setcountPDV_planifieee] = useState([]) //month

    //-------------------LISTE PL/ RELEVE / PART LINEAIRE / RUPTURE
    const [releve, setreleve] = useState([])
    const [pl, setpl] = useState([])
    const [promot, setpromot] = useState([])
    const [rupture, setrupture] = useState([])
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    //---------- LOADING --------------------
    const [loading, setloading] = useState(true)

    const traitement = () =>{
        getRapportGestionDashboard(today, datefin)
        .then((result) => {
            if (result?.data?.success === 1) {
                console.log(result?.data);
                // LISTE ALL USERS
                var allUsers = result?.data?.users
                /**
                 * DETAILS USERS
                 * {
                 *  user_code : ... , heure_debut : ... , heure_fin : ... 
                 * }
                 */
                var detailsUsers = result?.data?.details
                /**
                 * DETAILS iN / OUT MAGASIN
                 * {
                 *  user_code : ... , inMagasins : ... , OutPdv : ... 
                 * }
                 */
                var in_out_pdv = result?.data?.in_Out_Magasins
                //LISTE CONGE USERS  (null = aucun congé , sinon 1 )
                var conge = result?.data?.conge 
                // LISTE DES PDVS PLANIFIEES
                var pdv_planifiee = result?.data?.tableauMonth
                var nbr_pdvPlanfieeMonth = _.groupBy(result?.data?.nbr_pdvPlanfieeMonth,"user_code")
                setcountPDV_planifieee(nbr_pdvPlanfieeMonth)
                allUsers?.map((items)=>{
            
                    const liste_1 = detailsUsers?.filter((el)=>el.user_code === items.user_code)
                    const liste_2 = in_out_pdv?.filter((el)=>el.user_code === items.user_code)

                    if(liste_1[0]=== undefined){
                       
                        detailsUsers.push({
                            user_code : items.user_code,
                            heure_debut :"",
                            heure_fin: "",
                        })
                    }
                    if(liste_2[0]=== undefined){
                       
                        in_out_pdv.push({
                            user_code : items.user_code,
                            inMagasins :"",
                            OutPDV: "",
                        })
                    }
                
                })
                setpdv_planifiee(result?.data?.pdv_planifiee)
                setrupture(result?.data?.ruptures)
                setpl(result?.data?.partlineaire)
                setreleve(result?.data?.relevePrix)
                setpromot(result?.data?.promotions)
                let liste = _.merge(allUsers,_.uniq(detailsUsers) ,_.uniq(in_out_pdv),conge ,_.uniq(pdv_planifiee) ); 
                
                setlisteDash(liste)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(()=>{ 
            setloading(false)
        })

    }
    
    const findElementSearch = () => {
        if (today > datefin) {
            toast(t('messages.dateinf'), { type: "warning", position: "top-right" })
        } else {
            setloading(true)
            traitement()
        }
    }
    useEffect(()=>{
        traitement()
    },[])
    return (
        <div>

            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" /> {t('rapports.Rapport')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('Rapport de gestion globale')}</li>
                </ol>
            </div>
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-grid text-primary mr-2" /> {t('Filtre de gestion globale')}

                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <ToastContainer />
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className='row mb-2'>
                                <div className='col-3'></div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                                    <div className="input-group">
                                        <input type="date" value={today} onChange={(e) => settoday(e.target.value)} className="form-control text-primary fs-12" />
                                        <input type="date" value={fin} onChange={(e) => setfin(e.target.value)} className="form-control text-primary fs-12" />
                                        <span className="input-group-append">
                                            <button className="btn btn-primary" type="button" onClick={() => findElementSearch()} ><i className='ti-search' /></button>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-3'></div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='card' >
                        {loading ?<SqaureLoader />: (
                       <TableauAnalyse countPDV_planifieee={countPDV_planifieee} t={t} listeDash={listeDash} pdv_planifiee={pdv_planifiee} releve={releve} pl={pl}  promot={promot} rupture={rupture} date1={today} date2={fin}/>
                     
                       )}
                    </div>
                </div>
                
            </div>

        </div>
    )
}
